import { Injectable, ɵConsole } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AppConstantsService {
  private readonly ipformat =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  private SERVER_URL = "https://dev.vzfloorview.com";
  // public SERVER_URL = "http://localhost:8889";
  public readonly URL_CONSTANTS = {
    CURATOR_APP_URL: this.getServerURL() + "/curator/",
  };
  public static ACTIVITY_SHIPPED_LOCATION = "shipped to other location";
  public static ACTIVITY_GENERAL_SERVICE = "general service";
  public static ACTIVITY_RECEIVED_ASSET = "received asset"
  public static ACTIVITY_AUDIT = "Audit Checked";
  public static ACTIVITY_OTHER = "Other";
  public readonly MAX_PHOTOS_COUNT: number = 4;
  public readonly IDLE_TIMEOUT: number = 300;
  public readonly IDLE_BUFFER_TIMEOUT: number = 30;
  public readonly VERSION_NO: string = "v3.0.8";
  public QR_CODE_ACTIVITY_SHOWN: boolean = false;
  public readonly ERROR_CONSTANTS = {
    NETWORK_ERROR: "Something went wrong. Please try again later.",
    UNAUTHOURIZED_ACCESS: "Un-Authourized Access",
    UNAUTHOURIZED_BUILDING_MESSAGE: "You are not authorized to this building.",
    UNAUTHOURIZED_FLOOR_MESSAGE: "You are not authorized to this floor.",
    UNAUTHOURIZED_AREA_MESSAGE: "You are not authorized to this area.",
    NO_CAMERA_PERMISSION: "This device has no permission to access the camera",
    NO_CAMERA_FOUND: "No camera found on this device",
    INVALID_QR_CODE: "Invalid QR code scanned.",
    IVALID_HEAD_COUNT: "Invalid head count number entered.",
  };
  public static readonly GEOLOCATION_ERROR_MESSAGE =
    "Unable to find your current location, please select the location.";
  public static readonly GEOLOCATION_ERROR_STATUS_RESPONSE = "ERROR";
  public static readonly GEOLOCATION_SUCCESS_STATUS_RESPONSE = "SUCCESS";
  public static readonly LOCATION_ACCESS_ALLOWED_MESSAGE =
    "User agreed to make required location settings changes.";
  public static readonly LOCATION_ACCESS_DENIED_MESSAGE =
    "Access to location service is denied, defaulting to a location";
  public static readonly LOCATION_ACCES_REJECTION_MESSAGE =
    "Location accuracy change request is being rejected";
  public static readonly CACHE_SW_CLEARED_KEY = "CACHE_SW_CLEARED";

  constructor(private platform: Platform) { }

  isMobileApp() {
    return this.platform.is("cordova");
  }

  getServerURL() {
    if (
      !this.isMobileApp() &&
      (this.platform.is("desktop") ||
        this.platform.is("pwa") ||
        this.platform.is("mobileweb"))
    ) {
      return window.location.hostname === "localhost" ||
        window.location.hostname.match(this.ipformat)
        ? this.SERVER_URL
        : "https://" + window.location.hostname;
    } else {
      return this.SERVER_URL;
    }
  }

  getVersionNo() {
    return this.VERSION_NO;
  }

  setQrcodeActivityShownStatus(activityShown: boolean) {
    this.QR_CODE_ACTIVITY_SHOWN = activityShown;
  }

  getQrcodeActivityShownStatus() {
    return this.QR_CODE_ACTIVITY_SHOWN;
  }
}
