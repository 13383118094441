import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { retryWhen, delay, take, timeout, switchMap } from "rxjs/operators";
import { throwError, of } from "rxjs";
import { GenericApiReq, GenericService } from "../generic/generic.service";
import { AppConstantsService } from "../app-constants/app-constants.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  private API_TIMEOUT = 10000;

  constructor(
    private appConstantsService: AppConstantsService,
    private genericService: GenericService
  ) { }

  /**
   * Method to intercept the http requests
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let updatedRequest;
    const isMultiPartReq = request.url.includes("cmn/gateway/file");
    if (isMultiPartReq) {
      updatedRequest = request.clone({
        setHeaders: {
          Authorization:
            "Basic " + btoa("InternetUser" + ":" + "Covid19!Internet"),
        },
      });
    } else {
      updatedRequest = request.clone({
        setHeaders: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization:
            "Basic " + btoa("InternetUser" + ":" + "Covid19!Internet"),
        },
      });
    }
    if (!isMultiPartReq) {
      let params = {};
      const url = new URL(updatedRequest.url);
      url.searchParams.forEach((value, key) => {
        params[key] = isNaN(Number(value)) ? value : Number(value);
      });
      const payload: GenericApiReq = {
        endPoint: updatedRequest.url
          .substring(
            this.appConstantsService.URL_CONSTANTS.CURATOR_APP_URL.length
          )
          .split("?")[0],
        methodType: updatedRequest.method,
        content: updatedRequest.body,
        params: params,
      };
      updatedRequest.url = `${this.appConstantsService.URL_CONSTANTS.CURATOR_APP_URL}cmn/gateway`;
      updatedRequest.urlWithParams = updatedRequest.url;
      updatedRequest.body = payload;
      updatedRequest.method = "POST";
    }
    return next.handle(updatedRequest).pipe(
      timeout(this.API_TIMEOUT),
      retryWhen((errors) =>
        errors.pipe(
          delay(2000),
          take(3),
          switchMap((error, index) =>
            index === 2 ? throwError(error) : of(error)
          )
        )
      )
    );
  }
}
