import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { AppConstantsService } from "../app-constants/app-constants.service";

@Injectable({
  providedIn: "root",
})
export class GenericService {
  constructor(
    private httpClient: HttpClient,
    private appConstantsService: AppConstantsService
  ) {}

  genericApi(payload: GenericApiReq) {
    const url = `${this.appConstantsService.URL_CONSTANTS.CURATOR_APP_URL}cmn/gateway`;
    return this.httpClient.post(url, payload);
  }
}

export interface GenericApiReq {
  endPoint: string;
  methodType: string;
  params?: Object;
  content?: Object;
}

export enum ApiType {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}
