export enum ComponentType {
    LOGIN,
    LANDING,
    OPEN_ACTIVITIES,
    CHANGE_PASSWORD,
    FEEDBACK,
    FAILED_IMAGES,
    ASSET_LANDING,
    ASSET_OVERVIEW,
    ASSET_COMMENTS
}
